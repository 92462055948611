import React from 'react';
import {createBrowserRouter, createHashRouter, Outlet, RouterProvider,} from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import Header from "./components/Header/Header";
import Preview from "./pages/Preview";
import SelectedChannelProvider from "./context/channel/SelectedChannelContext";
import AccountProvider from "./context/account/AccountContext";

function App() {
    const Layout = () => (
        <>
            <Header/>
            <Outlet/>
        </>
    );

    const routers = createHashRouter([
        {
            element: <Layout/>,
            children: [
                {
                    path: "/",
                    element: <Home/>
                },
                {
                    path: "/preview",
                    element: <Preview/>
                }
            ]
        }
    ]);

    return (
        <div className="viewport">
            <AccountProvider>
                <SelectedChannelProvider>
                    <RouterProvider router={routers}/>
                </SelectedChannelProvider>
            </AccountProvider>
        </div>
    );
}

export default App;
