import React, {PropsWithChildren, useEffect, useReducer} from 'react';
import {getFetchError, getFetchLoading, getFetchSuccess} from "../../hooks/fetch/actions";
import {playlistService} from "../../services/playlist/PlaylistService";
import {ErrorResponse} from "../../hooks/fetch/types";
import {fetchReducer, initialFetchState} from "../../hooks/fetch/fetchReducer";

interface Props extends PropsWithChildren<any> {

}

function Statistics(props: Props) {

    const [state, dispatch] = useReducer(fetchReducer<number>(), initialFetchState);
    useEffect(() => {
        dispatch(getFetchLoading<number>());

        playlistService.countChannelsInPlaylist()
            .then((count: number) => dispatch(getFetchSuccess(count)))
            .catch((error: ErrorResponse) => dispatch(getFetchError(error)))

    }, []);

    const isLocalStorageSupported = (): Boolean => {
        if (!('indexedDB' in window)) {
            return false
        }
        return true
    }

    const Render = () => {
        if (!isLocalStorageSupported()) {
            return (<div>Nettleseren din støtter ikke lagring av kanaler lokalt</div>)
        }
        return (
            <>
                <div>Antall kanaler lagret lokalt i nettleseren: {state.data ?? 0}</div>
            </>)
    }

    return (
        <Render/>
    );
}

export default Statistics;
