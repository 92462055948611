import styles from "./channels.module.css";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList as List} from "react-window";
import {Row} from "./ChannelItem";
import React, {useEffect, useState} from "react";
import {M3uMedia, M3uPlaylist} from "../../lib/m3u";
import Button from "../common/Button";
import Input from "../common/Input";

type ItemProps = {
    playlist: M3uPlaylist
}

export const ShowPlaylist = ({playlist}: ItemProps) => {
    const inputCreateRef1 = React.createRef<HTMLInputElement>();
    const inputCreateRef2 = React.createRef<HTMLInputElement>();
    const [searchWords, setSearchWords] = useState<string[]>([]);
    const [filteredChannels, setFilteredChannels] = useState(playlist ? playlist.medias : []);

    const allChannels = playlist ? playlist.medias : [];

    const doFilter = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const value1 = inputCreateRef1.current?.value;
        const value2 = inputCreateRef2.current?.value;
        const words: string[] = []
        if (value1) {
            words.push(value1)
        }
        if (value2) {
            words.push(value2)
        }
        setSearchWords(words)
    };

    //useEffect for filtering playlist:
    useEffect(() => {
        console.log(`Number of channels before filter: ${allChannels.length}`)
        const result = applyFilters(allChannels, searchWords);
        console.log(`Number of channels after filter: ${result.length}`)
        setFilteredChannels(result);
    }, [searchWords]);

    function filter(channels: M3uMedia[], filterValue: string): M3uMedia[] {
        return channels.filter(({location, mimeType, name}) =>
            (!filterValue || location?.toLowerCase().search(filterValue.toLowerCase()) !== -1) ||
            (!filterValue || mimeType.toLowerCase().search(filterValue.toLowerCase()) !== -1) ||
            (!filterValue || name?.toLowerCase().search(filterValue.toLowerCase()) !== -1)
        );
    }

    /**
     * Advanced filter function. Will filter on name from all filterValues
     * @param channels
     * @param filterValues
     */
    function applyFilters(channels: M3uMedia[], filterValues: string[]): M3uMedia[] {
        let filteredChannels: M3uMedia[] = channels
        filterValues.forEach(value =>
        {
            filteredChannels = filter(filteredChannels, value);
        })
        return filteredChannels
    }

    return (
        <>
            <form onSubmit={doFilter} className="align_horizontally">
                <Input id="filter" placeholder="Filter 1" innerRef={inputCreateRef1} name="filter" type="text"
                       iconClassName="fas fa-lock"/>
                <Input id="filter" placeholder="Filter 2" innerRef={inputCreateRef2} name="filter" type="text"
                       iconClassName="fas fa-lock"/>

                <Button type="submit" text="Filtrer" iconClassName="fas fa-chevron-right"/>

            </form>
            <AutoSizer>
                {({height, width}: any) => (
                    <div>

                        <List
                            className={styles.list}
                            height={height}
                            itemData={filteredChannels}
                            itemCount={filteredChannels.length}
                            itemSize={50}
                            width={width}>
                            {Row}
                        </List>
                    </div>
                )}
            </AutoSizer>
        </>
    )
};