import React, {PropsWithChildren, useEffect, useReducer} from 'react';
import styles from "./channels.module.css";
import "../../App.css";
import {M3uPlaylist} from "../../lib/m3u";
import {getFetchError, getFetchLoading, getFetchSuccess} from "../../hooks/fetch/actions";
import {playlistService} from "../../services/playlist/PlaylistService";
import {ErrorResponse} from "../../hooks/fetch/types";
import {fetchReducer, initialFetchState} from "../../hooks/fetch/fetchReducer";
import {ShowPlaylist} from "./ShowPlaylist";
import Loading from "../common/Loading";

interface Props extends PropsWithChildren<any> {

}

function ChannelsList(props: Props) {
    const [playlist, dispatch] = useReducer(fetchReducer<M3uPlaylist>(), initialFetchState);

    useEffect(() => {
        dispatch(getFetchLoading<M3uPlaylist>());

        playlistService.getPlaylist()
            .then((playlist: M3uPlaylist) => dispatch(getFetchSuccess(playlist)))
            .catch((error: ErrorResponse) => dispatch(getFetchError(error)))

    }, []);

    const showContent = () => {
        if (playlist.isLoading) {
            return <Loading/>
        }
        if (playlist.error) {
            return <div className={styles.list}>Lasting feilet: {JSON.stringify(playlist.error)} </div>
        }
        if (!playlist.data) {
            return <div className={styles.list}>Ikke lastet ennå...</div>
        }

        return <ShowPlaylist playlist={playlist.data}/>
    }


    return (
        <>
            {showContent()}
        </>
    );
}

export default ChannelsList;
