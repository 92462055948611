import React from "react";
import Button from "../common/Button";
import {AccountContext} from "../../context/account/AccountContext";
import styles from "./account.module.css";

type DeleteAccountProps = {
    onDeleteAccount: () => void
}
const DeleteAccount = (props: DeleteAccountProps) => {
    const { data} = React.useContext(AccountContext);

    if (!data) {
        return null;
    }

    return (
        <div className={styles.delete_container}>
            <div className={styles.user_info}>
                <div>{data.url}:{data.port}</div>
                <div>Brukernavn: {data.username}</div>
            </div>
            <Button text="Slett konto" onClick={props.onDeleteAccount} />
        </div>
    );
}

export default DeleteAccount;