import React, {useReducer, useState} from 'react';
import "../../App.css";
import {M3uPlaylist} from "../../lib/m3u";
import {m3uResponseExtractor} from "../../hooks/fetch/responseExtractors";
import {callBackend} from "../../hooks/fetch/api";
import {getFetchError, getFetchLoading, getFetchSuccess} from "../../hooks/fetch/actions";
import {playlistService} from "../../services/playlist/PlaylistService";
import {ErrorResponse} from "../../hooks/fetch/types";
import {fetchReducer, initialFetchState} from "../../hooks/fetch/fetchReducer";
import Button from "../common/Button";
import {AccountContext} from "../../context/account/AccountContext";
import Loading from "../common/Loading";

function DownloadPlaylistWithAccountData() {
    const {data} = React.useContext(AccountContext);
    const [isSending, setIsSending] = useState(false)
    const [state, dispatch] = useReducer(fetchReducer<M3uPlaylist>(), initialFetchState);

    const downloadFromInternet = async () => {
        if (data) {
            dispatch(getFetchLoading<M3uPlaylist>());

            //return callBackend<M3uPlaylist>("GET", "/assets/tv_channels_short.m3u", {}, {}, m3uResponseExtractor)
            return callBackend<M3uPlaylist>("GET", `${data.url}:${data.port}/get.php?username=${data.username}&password=${data.password}&type=m3u&output=ts`, {}, {}, m3uResponseExtractor)
        }
    }


    const storePlaylistInDatabase = (playlist?: M3uPlaylist) => {
        console.log("DownloadPlaylistWithAccountData.storePlaylistInDatabase")

        if (playlist) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            playlistService.save(playlist)
                .then(() => {
                    console.log("SaveAll OK!")
                    dispatch(getFetchSuccess<M3uPlaylist>(playlist));
                })
                .catch((error: ErrorResponse) => {
                    console.log(`SaveAll ERROR ${JSON.stringify(error)}`)
                })
                .finally(() => setIsSending(false)) // once the request is sent, update state again
        }
    }


    const downloadPlaylist = () => {
        downloadFromInternet()
            .then(t => {
                console.log(`DownloadPlaylistWithAccountData downloadPlaylist() - fetchData - getFetchSuccess`);
                // dispatch(getFetchSuccess<M3uPlaylist>(t));
                storePlaylistInDatabase(t)
            })
            .catch(error => {
                console.log("DownloadPlaylistWithAccountData downloadPlaylist() - fetchData - error" + JSON.stringify(error));
                dispatch(getFetchError<M3uPlaylist>(error as ErrorResponse));
            });
    }

    const disableDownloadButton = () => {
        if ((!data) && state.isLoading) {
            return true
        }
        return false
    }

    const showContent = () => {
        if (state.isLoading) {
            return <Loading coverParentOnly={true}/>
        }
        if (state.error) {
            console.log(`Lasting feilet: error: ${JSON.stringify(state.error)}`)
        }

        return <>
            <Button disabled={disableDownloadButton()} text="Last ned spilleliste" onClick={() => downloadPlaylist()}/>
        </>
    }

    return (
        <>
            {showContent()}
        </>
    );
}

export default DownloadPlaylistWithAccountData;
